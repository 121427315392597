import { MulticloudTelemetryHelper } from "multi-cloud-shared-components";
import { CloudLinkType } from "../../Type";
import { getCloudType } from "../../utils/CommonUtil";
import AwsHeader from "./AwsHeader";
import AzureHeader from "./AzureHeader";
import GcpHeader from "./GcpHeader";
import OciHeader from "./OciHeader";

type HeaderProps = {
  isOracleHeader?: boolean;
  includeLogo?: boolean;
};

const HeaderContainer = ({ isOracleHeader, includeLogo }: HeaderProps) => {
  //TODO: if not OCI header, get the cloud type from Auth object
  //Convert to const after update
  const headerType = isOracleHeader ? "oci" : getCloudType();

  switch (headerType) {
    case CloudLinkType.AWS:
      return <AwsHeader />;
    case CloudLinkType.AZURE:
      return <AzureHeader />;
    case CloudLinkType.GCP:
      return <GcpHeader />;
    default:
      return <OciHeader includeLogo={includeLogo} />;
  }
};

export default MulticloudTelemetryHelper.withMcRenderMetrics(HeaderContainer);
